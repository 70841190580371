/* -'Media' block
-----------------------------------------------------------------------------*/
.media-block-container {
  @include section-margin;

  .intro--has-content {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2;
    }
  }
  .media-content {
    @include section-padding;

    content: '';
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    color: white;
  }
  .media-content-inner {
    > *:last-child {
      margin-bottom: 0;
    }
  }
}
