/* -Event cancellation page
---------------------------------------------------------------------------- */
.event-cancellation {
  .event-cancellation__event-title {
    font-weight: $font-weight-bold;
  }
  form {
    margin-top: rem(20px);
    margin-bottom: rem(5px);

    label {
      font-weight: $font-weight-bold;
    }
  }
}
